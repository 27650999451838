import looper from './looper.svg';
import './App.css';
import logo from './logo.svg'
import backgrounds from './looper.svg'
import clipPathGroupOne from './clipPathGroup.svg'
import clipPathGroupTwo from './clipPathGroupTwo.svg'
import instagram from './instagran.svg'
import facebook from './facebook.svg'
import twiter from './twiter.svg'
import linkedln from './linkedln.svg'
import youtube from './youtube.svg'
import CommingSoon from './comming-soon';
import PreApplication from './preApplication';
import Intro from './Intro'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState } from 'react';
function App() {
  
  const [showIntro, setShowIntro] = useState(true);
  const handleAnimationEnd = () => {
    setShowIntro(false);
  };
  return (
    <Router>
      {showIntro && <Intro onAnimationEnd={handleAnimationEnd} />}
      {!showIntro && (
<div className="comming-soon"> 
      <Routes>
          <Route path="/" element={<CommingSoon />} />
          {/* <Route path="/on-basvuru/" element={<PreApplication />} /> */}
        </Routes>
      
    </div>
     )}
    </Router>
    
  );
}

export default App;
