import looper from './looper.svg';
import './App.css';
import logo from './logo.svg'
import backgrounds from './looper.svg'
import clipPathGroupOne from './clipPathGroup.svg'
import clipPathGroupTwo from './clipPathGroupTwo.svg'
import instagram from './instagran.svg'
import facebook from './facebook.svg'
import twiter from './twiter.svg'
import linkedln from './linkedln.svg'
import youtube from './youtube.svg'
import { Link } from 'react-router-dom';
function CommingSoon() {
  return (
    <div className="comming-soon"> 
      <div className='text-area'>
        <img className='image' src={logo} alt='logo'/>
        <h1 className='main-title'>Çok Yakında Buradayız!</h1>
        <p className='text'>İşletmenizin geleceğini şekillendirecek kripto ödeme çözümleri için hazırlanın!</p>
        <h3 className='title'>Techdea Kripto Sanal POS, yakında sizlerle buluşacak.</h3>
        <div className='contacts'>
        <div className='iletisime-gec-area'>
        <a href="mailto:hello@techdea.com" className='iletisime-gec'>
           İletişime Geç
           </a> 
           </div>
          <div className='on-basvuru-area'>
            <a href='https://techdea.com/on-basvuru/' className='on-basvuru'>
              Ön Başvuru
            </a>
          </div>
        </div>
      </div> 
      <div className='phones'> 
          <img className='clipOne' src={clipPathGroupOne} alt='clipOne'/>
          <img className='clipTwo' src={clipPathGroupTwo} alt='clipOne'/>
        </div>
      <div className='footer-area'>
        <div className='social-network'>
          <a href=''><img src={facebook} alt='facebook'/></a>
          <a href='https://x.com/techdeacom'><img src={twiter} alt='twiter'/></a>
          <a href='https://www.instagram.com/techdeacom/'><img src={instagram} alt='instagram'/></a>
          <a href='https://www.linkedin.com/company/techdeacom'><img src={linkedln} alt='linkedln'/></a>
          <a href='https://www.instagram.com/techdeacom/'><img src={youtube} alt='youtube'/></a>
        </div>
        <p className='copyrigth'>© 2024 Techdea Tüm Hakları Saklıdır.</p>
      </div> 
      
    </div>
  );
}

export default CommingSoon;
