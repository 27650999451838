import React, { useEffect, useState } from 'react';
import './Intro.css'; // CSS dosyası
import './App.css'
const Intro = ({ onAnimationEnd }) => {
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
      // Intro animasyonu tamamlandıktan sonra fade-out işlemi başlat
      const timer = setTimeout(() => {
        setFadeOut(true);
        setTimeout(onAnimationEnd, 500); // Fade-out animasyon süresi
      }, 2000); // Animasyon süresi
  
      return () => clearTimeout(timer);
    }, [onAnimationEnd]);
  return (
    
    <div className={`intro ${fadeOut ? 'fade-out' : ''} comming-soon`}>
      <div className="svg-container">
      <svg className="svg" viewBox="0 0 400 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<g >
<path id='path1' className='pths' d="M400 25.6354H386.113V77.2259H400V25.6354Z" fill="#5E53A2"/>
<path id='path2' className='pths' d="M357.28 78.2948C352.582 78.2948 348.343 77.1208 344.568 74.7706C340.792 72.4204 337.835 69.2137 335.697 65.155C333.559 61.101 332.499 56.5422 332.499 51.4854C332.499 46.4287 333.559 41.7694 335.697 37.7085C337.835 33.6499 340.792 30.4431 344.568 28.0929C348.343 25.7427 352.58 24.5688 357.28 24.5688C360.978 24.5688 364.292 25.3202 367.209 26.8116C370.128 28.3099 372.458 30.3883 374.212 33.0629C375.954 35.7306 376.898 38.7408 377.044 42.0869V60.6716C376.9 64.0907 375.957 67.1147 374.212 69.7481C372.458 72.3861 370.128 74.4668 367.209 75.9971C364.292 77.5274 360.978 78.2948 357.28 78.2948ZM359.841 65.372C363.751 65.372 366.928 64.0724 369.347 61.471C371.765 58.8718 372.978 55.5098 372.978 51.3781C372.978 48.6716 372.43 46.2688 371.322 44.1699C370.215 42.0709 368.694 40.4287 366.729 39.2525C364.774 38.0785 362.472 37.4892 359.843 37.4892C357.214 37.4892 355.019 38.0762 353.064 39.2525C351.1 40.4264 349.567 42.0686 348.469 44.1699C347.361 46.2688 346.813 48.6716 346.813 51.3781C346.813 54.0846 347.361 56.5947 348.469 58.6937C349.567 60.7972 351.098 62.4348 353.064 63.6111C355.019 64.785 357.283 65.3743 359.843 65.3743M372.227 77.2259V63.3393L374.47 50.7386L372.227 38.348V25.6354H386.113V77.2259H372.227Z" fill="#1F2B38"/>
<path id='path3' className='pths' d="M287.258 76.0519C283.666 74.5536 280.623 72.3496 278.129 69.4307L286.354 61.2061C287.923 62.9876 289.773 64.3214 291.911 65.2121C294.049 66.1029 296.428 66.546 299.066 66.546C301.983 66.546 304.546 65.9247 306.752 64.6777C308.967 63.4306 310.653 61.6491 311.827 59.3377C313.003 57.0218 313.59 54.297 313.59 51.1657C313.59 48.0343 313.04 45.4512 311.932 43.206C310.833 40.9632 309.246 39.2205 307.186 37.9711C305.114 36.7287 302.698 36.1028 299.925 36.1028C297.152 36.1028 295.063 36.6556 293.242 37.7587C291.431 38.8619 290.045 40.4447 289.081 42.5117C288.117 44.5787 287.569 47.139 287.425 50.2018L281.763 45.8212L318.608 45.9308V56.3937L275.242 56.2886C274.964 55.0804 274.769 53.991 274.655 53.034C274.55 52.0701 274.502 51.1269 274.502 50.2018C274.502 45.2913 275.589 40.8946 277.757 37.0096C279.934 33.1337 282.919 30.0709 286.733 27.828C290.536 25.5828 294.937 24.4614 299.925 24.4614C304.913 24.4614 309.575 25.6559 313.698 28.0381C317.829 30.4272 321.084 33.6293 323.473 37.6537C325.862 41.6735 327.054 46.2483 327.054 51.3781C327.054 56.5079 325.821 61.206 323.366 65.2647C320.91 69.3233 317.578 72.5117 313.38 74.8231C309.182 77.1391 304.377 78.2948 298.964 78.2948C294.763 78.2948 290.865 77.5479 287.263 76.0519" fill="#1F2B38"/>
<path id='path4' className='pths' d="M236.91 78.2948C232.134 78.2948 227.868 77.1208 224.093 74.7706C220.317 72.4204 217.341 69.2137 215.176 65.155C212.999 61.101 211.921 56.5422 211.921 51.4854C211.921 46.4287 212.999 41.7694 215.176 37.7085C217.343 33.6499 220.299 30.4431 224.035 28.0929C227.781 25.7427 232.068 24.5688 236.91 24.5688C240.608 24.5688 243.94 25.3202 246.896 26.8116C249.851 28.3099 252.231 30.3883 254.052 33.0629C255.872 35.7306 256.845 38.7408 256.989 42.0869V60.4569C256.845 63.8075 255.881 66.8315 254.109 69.538C252.327 72.2445 249.938 74.3823 246.944 75.9468C243.959 77.5114 240.608 78.2971 236.91 78.2971M239.26 65.372C241.889 65.372 244.192 64.785 246.147 63.6088C248.111 62.4325 249.634 60.7972 250.74 58.6914C251.847 56.5924 252.398 54.1554 252.398 51.3758C252.398 48.5962 251.847 46.2665 250.74 44.1676C249.632 42.0686 248.111 40.4264 246.147 39.2502C244.192 38.0762 241.928 37.4869 239.368 37.4869C236.807 37.4869 234.437 38.0945 232.482 39.3027C230.517 40.5155 228.985 42.1531 227.889 44.2201C226.781 46.2848 226.233 48.6693 226.233 51.3758C226.233 54.0823 226.781 56.5924 227.889 58.6914C228.985 60.7949 230.536 62.4325 232.53 63.6088C234.524 64.7828 236.766 65.372 239.26 65.372ZM265.533 77.2259H251.646V63.3393L253.889 50.7386L251.539 38.348V0H265.531V77.2259H265.533Z" fill="#1F2B38"/>
<path id='path5' className='pths' d="M155.082 0H169.074V77.2259H155.082V0ZM189.799 77.2259V47.6415C189.799 44.5787 188.835 42.0846 186.909 40.1638C184.993 38.2429 182.499 37.2791 179.436 37.2791C177.442 37.2791 175.661 37.7062 174.092 38.5604C172.532 39.4169 171.298 40.6274 170.412 42.1919C169.517 43.761 169.074 45.5768 169.074 47.6415L163.633 44.8619C163.633 40.8033 164.481 37.2585 166.196 34.2346C167.9 31.206 170.268 28.8421 173.295 27.1314C176.319 25.423 179.795 24.5665 183.714 24.5665C187.633 24.5665 191.1 25.423 194.124 27.1314C197.148 28.8421 199.517 31.19 201.232 34.182C202.936 37.1717 203.793 40.6251 203.793 44.5399V77.2259H189.801H189.799Z" fill="#1F2B38"/>
<path id='path6' className='pths' d="M126.896 78.2948C121.626 78.2948 116.889 77.1208 112.691 74.7706C108.491 72.4204 105.179 69.2137 102.752 65.1551C100.333 61.101 99.1201 56.5422 99.1201 51.4854C99.1201 46.4287 100.333 41.7694 102.752 37.7085C105.179 33.6499 108.5 30.4294 112.737 28.0404C116.974 25.6559 121.693 24.4637 126.893 24.4637C130.947 24.4637 134.695 25.2106 138.112 26.7066C141.531 28.198 144.478 30.3358 146.972 33.1154L138.007 42.1919C136.648 40.6982 135.031 39.5585 133.145 38.7728C131.256 37.994 129.177 37.5989 126.893 37.5989C124.333 37.5989 122.031 38.1858 120.007 39.3621C117.974 40.536 116.378 42.1417 115.202 44.1721C114.025 46.2003 113.438 48.6031 113.438 51.3804C113.438 54.1577 114.025 56.4896 115.202 58.5886C116.376 60.6922 117.974 62.3298 120.007 63.5037C122.031 64.68 124.331 65.267 126.893 65.267C129.243 65.267 131.361 64.8581 133.25 64.0382C135.136 63.2205 136.755 62.026 138.112 60.4569L147.184 69.538C144.555 72.3884 141.531 74.5559 138.112 76.0519C134.693 77.5479 130.947 78.2948 126.893 78.2948" fill="#1F2B38"/>
<path id='path7' className='pths' d="M116.826 51.4306C116.826 57.1725 121.48 61.8273 127.222 61.8273C132.964 61.8273 137.617 57.1725 137.617 51.4306C137.617 45.6887 132.962 41.034 127.222 41.034C121.483 41.034 116.826 45.6887 116.826 51.4306Z" fill="#5E53A2"/>
<path id='path8' className='pths' d="M69.4215 78.2948C64.0085 78.2948 59.203 77.1391 55.0051 74.8231C50.8071 72.5117 47.4748 69.3256 45.0195 65.2647C42.5642 61.2061 41.3309 56.5787 41.3309 51.3781C41.3309 46.1775 42.5254 41.6758 44.9122 37.6559C47.3012 33.6316 50.5559 30.4294 54.6876 28.0404C58.8102 25.6559 63.4032 24.4637 68.46 24.4637C73.5167 24.4637 77.8494 25.5851 81.6522 27.828C85.4665 30.0731 88.4516 33.1337 90.6283 37.0119C92.7958 40.8992 93.8829 45.2936 93.8829 50.2041C93.8829 51.1291 93.835 52.0724 93.7299 53.0363C93.6134 53.9955 93.4216 55.0827 93.1429 56.2909L49.777 56.396V45.9331L86.6222 45.8235L80.9602 50.2041C80.8163 47.1413 80.2659 44.581 79.3043 42.514C78.3405 40.447 76.9541 38.8642 75.1429 37.761C73.3226 36.6579 71.098 36.1051 68.46 36.1051C65.6872 36.1051 63.2685 36.7309 61.1992 37.9734C59.1391 39.2205 57.5494 40.9632 56.4508 43.2083C55.3431 45.4512 54.7949 48.1052 54.7949 51.168C54.7949 54.2308 55.3819 57.0241 56.5582 59.34C57.7344 61.6514 59.4177 63.4329 61.6332 64.68C63.8372 65.927 66.3998 66.5483 69.3187 66.5483C71.9567 66.5483 74.3366 66.1052 76.4744 65.2144C78.6123 64.3237 80.46 62.9898 82.0314 61.2083L90.256 69.4329C87.7619 72.3496 84.7173 74.5559 81.1269 76.0542C77.5251 77.5502 73.624 78.2971 69.4261 78.2971" fill="#1F2B38"/>
<path id='path9' className='pths' d="M0 24.5665H38.1356V36.8497H0V24.5665ZM12.0731 77.2259L12.0685 0H26.0602L26.0648 77.2259H12.0731Z" fill="#1F2B38"/>
<path id='path10' className='pths' d="M38.1356 24.5665H0V36.8497H38.1356V24.5665Z" fill="#5E53A2"/>
</g>
</svg>
</div>
    </div>
  );
}

export default Intro;
