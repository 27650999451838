import './App.css'; 
import './Pre.css'
import logo from './logo.svg'
import preInfoOne from './preInfoOne.svg'
import preInfoTwo from './preInfoTwo.svg'
import preInfoThree from './preInfoThree.svg'

import instagram from './instagran.svg'
import facebook from './facebook.svg'
import twiter from './twiter.svg'
import linkedln from './linkedln.svg'
import youtube from './youtube.svg'
function PreApplication() {
  return (
    <div className="comming-soon">  
        <div className='pre-logo'>
            <img src={logo} alt='logo'/>
        </div>
        <div className='pre-top'>
            <p className='pre-top-title'>Techdea Kripto POS Ön Başvuru Formu</p>
            <p className='pre-top-description'>Techdea Kripto POS çözümlerimizden yararlanmak için ilk adımı atın! </p>
        </div>
        <form className='pre-form'>
            <div class="input-container">
                <input type="text" placeholder=" " required />
                <label for="yetkiliadi">Yetkili Adı</label>
            </div>
            <div class="input-container">
                <input type="text" placeholder=" " required />
                <label for="yetkilisoyadi">Yetkili Soyadı</label>
            </div>
            <div class="input-container">
                <input type="email" placeholder=" " required />
                <label for="yetkiliemailadresi">Yetkili E-Mail Adresi</label>
            </div>
            <div class="input-container">
                <input type="tel" placeholder=" " required />
                <label for="yetkilitelefonnumarası">Yetkili Telefon Numarası</label>
            </div>
            <div class="input-container">
                <input type="text" placeholder=" " required />
                <label for="sirketunvanı">Şirket Unvanı</label>
            </div>
            <div class="input-container">
                <input type="text" placeholder=" " required />
                <label for="verginumarası">Vergi Numarası</label>
            </div>
            <div class="input-container">
                <input type="text" placeholder=" " required />
                <label for="vergidairesi">Vergi Dairesi</label>
            </div>
            <div class="input-container">
                <input type="text" placeholder=" " required />
                <label for="internetsitesi">İnternet Sitesi</label>
            </div>
            <div class="checkbox-container">
                <input type="checkbox" />
                <label for="personalData">
                    <p>Girdiğim kişisel verilerimin <span>Aydınlatma Metni</span> kapsamında işlenmesini kabul ediyorum.</p>
                </label>
            </div> 
            <div class="checkbox-container">
                <input type="checkbox" className='custom-checkbox'  />
                <label >
                    <p><span>Üye İş Sözleşmesini</span>, okudum, onaylıyorum.</p>
                </label>
            </div>
        </form>
        <div className='pre-info'>
            <div className='pre-info-box'>
                <img src={preInfoOne} alt='preInfo'/>
                <p className='pre-info-box-title'>Başvurunuzu yapın, aramıza katılın</p> 
                <p className='pre-info-box-description'>Ön başvuru formunu doldurarak 
                Techdea kapısını aralayın.</p>
            </div>
            <div className='pre-info-box'>
                <img src={preInfoTwo} alt='preInfo'/>
                <p className='pre-info-box-title'>Başvuru sonucunuzu kontrol edin</p>
                <p className='pre-info-box-description'>Başvuru sonucunuz olumlu ise o 
                kapıdan geçmişsiniz demektir.
</p>
            </div>
            <div className='pre-info-box'>
                <img src={preInfoThree} alt='preInfo'/>
                <p className='pre-info-box-title'>Techdea’ya hoş geldiniz</p>
                <p className='pre-info-box-description'>Sürecinizin olumlu değerlendirildiği anlatan mail 
size ulaştığında güvenli ödeme almaya başlayabilirsiniz.
</p>
            </div>
        </div>
        <div className='pre-social-network'>
        <div className='social-network'>
          <a href=''><img src={facebook} alt='facebook'/></a>
          <a href='https://x.com/techdeacom'><img src={twiter} alt='twiter'/></a>
          <a href='https://www.instagram.com/techdeacom/'><img src={instagram} alt='instagram'/></a>
          <a href='https://www.linkedin.com/company/techdeacom'><img src={linkedln} alt='linkedln'/></a>
          <a href='https://www.instagram.com/techdeacom/'><img src={youtube} alt='youtube'/></a>
        </div>
        </div>
        <div className='pre-footer'>
            <p className='pre-footer-p'>Formu doldurarak başvurunuzu tamamlayın.</p>
            <a className='pre-footer-a'>Başvuruyu Tamamla
            </a>
        </div>
    </div>
  );
}

export default PreApplication;
